// En Carrito.jsx
import React, { useContext, useEffect } from 'react';
import { useCarrito } from './CarritoContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import NavigationBar from '../../Components/Nav/NavigationBar';
import fotoUriel from '../../assets/Productos/uriel_y_polo/uriel&polo.webp';
import Footer from '../../Components/Footer/Footer';
import './carrito.css';

const Carrito = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { carrito, actualizarCarrito } = useCarrito();

  const aumentarCantidad = (id) => {
    const nuevoCarrito = carrito.map((producto) => {
      if (producto.id === id) {
        return { ...producto, cantidad: producto.cantidad + 1 };
      }
      return producto;
    });
    actualizarCarrito(nuevoCarrito);
  };

  const disminuirCantidad = (id) => {
    const nuevoCarrito = carrito.map((producto) => {
      if (producto.id === id && producto.cantidad > 0) {
        return { ...producto, cantidad: producto.cantidad - 1 };
      }
      return producto;
    });
    actualizarCarrito(nuevoCarrito);
  };

  const eliminarProducto = (id) => {
    const nuevoCarrito = carrito.filter((producto) => producto.id !== id);
    actualizarCarrito(nuevoCarrito);
  };

  const pagar = () => {
    const items = carrito.map((producto) => ({
      id: producto.id,
      quantity: producto.cantidad,
    }));

    const bodyJson = {
      items: items,
    };

    // Realizar la solicitud POST a la API
    fetch('https://stripe-api-production.up.railway.app/api/payment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyJson),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Respuesta de la API:', data);

        // Limpiar el carrito después de que la API responda exitosamente
        actualizarCarrito([]);
        
        // Abrir la URL de pago en la misma página
        window.location.href = data.url;
      })
      .catch((error) => {
        console.error('Error al enviar la solicitud:', error);
      });
  };

  return (
    <>
      <NavigationBar />
      <div className='container text-center'>
        <h2>Carrito de Compras</h2>
        {carrito.length === 0 ? (
          <p>El carrito está vacío.</p>
        ) : (
          <div>
            {carrito.map((producto) => (
              <div key={producto.id} className='container mt-3'>
                <div className='row border p-3'>
                  <div className='col-lg-4'>
                    <img src={producto.imagen} alt={producto.nombre} className='imgCarrito' />
                    
                    <div className='p-3'>
                    <b>{producto.nombre}</b>
                    <br />
                      <b>Precio: ${Number((producto.preciounit).toFixed(2)).toLocaleString('es-MX', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}</b>
                    </div>
                  </div>
                  <div className='col-lg-4 d-flex align-items-center'>
                    <div className=''>
                      <b>Cantidad:</b>
                    </div>
                    <div>
                      <button onClick={() => aumentarCantidad(producto.id)} className='btn btn-warning mx-2'>
                        +
                      </button>
                    </div>
                    <div className=''>
                      <b>{producto.cantidad}</b>
                    </div>
                    <div>
                      <button onClick={() => disminuirCantidad(producto.id)} className='btn btn-warning mx-2'>
                        -
                      </button>
                    </div>
                  </div>
                  <div className='col-lg-4 d-flex align-items-center'>
                    <div className=''>
                    <b>Total: ${Number((producto.precio * producto.cantidad).toFixed(2)).toLocaleString('es-MX', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}</b>
                    </div>
                    <div className='ml-auto'>
                      <FontAwesomeIcon icon={faTrash} className='text-danger ml-5 m-3' onClick={() => eliminarProducto(producto.id)} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <button onClick={pagar} className='btn btn-warning text-dark mt-3'>
              Pagar
            </button>
          </div>
        )}
      </div>
      <div className='padingCar'>
      <Footer/>
      </div>
    </>
  );
};

export default Carrito;
