import React, {useEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NavigationBar from '../../Components/Nav/NavigationBar';
import BannerColab from '../../assets/img/Banner_colaboración.webp';
import Claudina from '../../assets/img/Claudina_colaboracion.webp';
import Texto_Claudina from '../../assets/img/texto_claudina.webp';
import Maestros from '../../assets/img/maestros_del_oficio.webp';
import CarrouselColaboracion from '../../Components/CarrouselColaboracion/CarrouselColaboracion';
import Flotante from '../Contacto/botonFlotante/Flotante';
import Footer from '../../Components/Footer/Footer';
import './colaboracion.css';

const Colaboracion = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return (
        <>
            <NavigationBar />
            <div className='contenedorIMG'>
                <img src={BannerColab} alt="" className='colabImg' />
            </div>

            <Container fluid className="p-0">
                <Row className="m-0 ">
                    <Col sm={12} md={6} className="p-0"><img src={Claudina} alt="" className='colabImg' /></Col>
                    <Col sm={12} md={6} className="p-0">
                        <img src={Texto_Claudina} alt="" className='colabImg' />
                    </Col>
                </Row>
            </Container>

            <div className='pt-5'>
                <Row className="m-0 ">
                    <Col sm={12} md={6} className="p-0 text-start d-flex align-items-center justify-content-center">
                        <div className='m-5'>
                            <h1 className='titleText'>MAESTROS DEL OFICIO</h1>
                            <p className='textArea'>
                                Una colección de complementos creada por <b>ESTUDIO CLAUDINA FLORES_</b> en exclusiva para <b>LABENZE</b> design furniture, que pretende honrar el diseño mexicano a través de mobiliario y objetos utilitarios pensado en habitar los espacios de la vida cotidiana, en respuesta a distintos usos y funciones.
                            </p>
                            <p className='textArea'>
                                Parte esencial de esta propuesta, son los materiales naturales y procesos artesanales que celebran el oficio tradicional, creando diálogos de colaboración entre la industria y el diseño local.
                            </p>
                        </div>
                    </Col>
                    <Col sm={12} md={6} className="p-5 text-center"><img src={Maestros} alt="" style={{ width: '700%', maxWidth: '90%' }} />
                    </Col>
                </Row>
            </div>

   
           <CarrouselColaboracion/>

        <div className='text-center pt-5'>
        <iframe width="80%" height="700" src="https://www.youtube.com/embed/opiaqj7UGJ4?si=luObKj6Tky_HHM8U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <Flotante/>
        <Footer/>
        </>
    );
}

export default Colaboracion;