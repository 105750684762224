import React from 'react';
import {Container} from 'react-bootstrap';
import imagen1 from '../../../assets/Productos/igor/Materialidad/Materialidad_Igor_Original.webp';
import imagen2 from '../../../assets/Productos/igor/Materialidad/Materialidad_Igor_Ocre.webp';
import imagen3 from '../../../assets/Productos/igor/Materialidad/Materialidad_Igor_Neutra.webp';
import imagen4 from '../../../assets/Productos/igor/Materialidad/Materialidad_Igor_Azules.webp';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Keyboard, Pagination, Navigation } from 'swiper/modules';

import './galeryigor.css';


const GaleryIgor= () => {
  return (
    <Container className='pt-3'>
    <Swiper 
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        keyboard={{
          enabled: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Keyboard, Pagination, Navigation]}
        className="mySwiper text-dark"
      >
        <SwiperSlide><img src={imagen1} alt="" /></SwiperSlide>
        <SwiperSlide><img src={imagen2} alt="" /></SwiperSlide>
        <SwiperSlide><img src={imagen3} alt="" /></SwiperSlide>
        <SwiperSlide><img src={imagen4} alt="" /></SwiperSlide>
      </Swiper>
    </Container>
  );
}

export default GaleryIgor;
