import React, {useState} from 'react';
import {Container, Row, Col, Navbar, Nav, Dropdown, Button} from 'react-bootstrap'; 
import Contador from '../../Pages/Carrito/Contador/Contador';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo/LogoDiagrama.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './navbar.css';
import 'animate.css';

const NavigationBar = () => {
  const [mostrarDropdown, setMostrarDropdown] = useState(false);

  const handleDropdownToggle = () => {
    setMostrarDropdown(!mostrarDropdown);
  };

  return (
   <>
    {/* <Container fluid className='navbarColor'>
    <Row>
      <div className='col-sm-6 col-md-6 col-lg-6 d-flex justify-content-start align-items-center'>
        <Link to="/"> <img src={logo} alt="" className='img pl-5' /></Link>
      </div>


      <div className='col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end align-items-center'>
        <Link to="/" className='opcion texto-con-zoom'>Concepto</Link>
        <Link to="/colaboracion" className='opcion texto-con-zoom'>Colaboración</Link>
        <Link to="/piezas" className='opcion texto-con-zoom'>Piezas</Link>
        <Link to="/carrito" className='opcion texto-con-zoom'><Contador /></Link>
      </div>
    </Row>
  </Container> */}


<Container fluid className='navbarColor'>
      <Row>
        <div className='col-6 d-flex justify-content-start align-items-center'>
          <Link to="/"> <img src={logo} alt="" className='img pl-3' /></Link>
        </div>

        <div className='col-6 d-flex justify-content-end align-items-center'>
          {/* Dropdown visible solo en dispositivos móviles */}
          <Dropdown show={mostrarDropdown} onToggle={handleDropdownToggle} className="d-md-none">
            <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ background: 'black', border: 'none' }}>
              <FontAwesomeIcon icon={faBars} color="white" size="2x" style={{ fontSize: '24px' }} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/" className=''>Concepto</Dropdown.Item>
              <Dropdown.Item as={Link} to="/colaboracion" className=' '>Colaboración</Dropdown.Item>
              <Dropdown.Item as={Link} to="/piezas" className=' '>Piezas</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* Opciones normales del menú para resoluciones mayores a md (medium) */}
          <div className="d-none d-md-flex">
            <Link to="/" className='opcion texto-con-zoom'>Concepto</Link>
            <Link to="/colaboracion" className='opcion texto-con-zoom'>Colaboración</Link>
            <Link to="/piezas" className='opcion texto-con-zoom'>Piezas</Link>
          </div>

          <Link to="/carrito" className='opcion texto-con-zoom'><Contador /></Link>
        </div>
      </Row>
    </Container>
   </>
  );
}

export default NavigationBar;