import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import labenzeFooter from '../../assets/img/labenze-footer.png';
import facebook from '../../assets/img/icon-facebook.png';
import instagram from '../../assets/img/icon-instagram.png';

import './Footer.css';

const Footer = () => {
  return (
   <>
    <footer className='footerColor'>
      <div className='row align-items-center justify-content-center'>
        <div className="col-sm-4 col-md-4 col-lg-4 text-start">
          <img src={labenzeFooter} alt="" className='footer-img' />
          <p className='pt-2 text-white text-justify'>
            <span>El diseño y estilo de vida conforman <br /> la esencia de las creaciones de los <br /> más destacados diseñadores <br/>europeos, brindando una amplia <br/> gama de colores, materiales, <br/> procesos y acabados para generar <br/> propuestas innovadoras.</span>
          </p>
        </div>

        <div className="col-sm-4 col-md-4 col-lg-4 text-start">
          <div className='text-white'>
            <h2>Contáctanos</h2>
            <p className='pt-3'>
              <span>Avenida Paseo Punto Sur No. 312
                 <br /> Nivel 1, Interior V2
                 <br />Corporativo Punto Sur
                 <br />Tlajomulco de Zúñiga, Jalisco, México.
                 <br /> hola@gruporequiez.com
                 <br />+52 (33) 3833.7788
              </span>
            </p>
          </div>
        </div>

        <div className="col-sm-3 col-md-3 col-lg-3 text-start">
          <div className='text-white'>
            <h4>Redes Sociales</h4>
            <a href='https://www.facebook.com/LabenzeDesign/?locale=es_LA' target="_blank"><img src={facebook} alt="" className='icon'/></a>
            <a href='https://www.instagram.com/labenzedesign/' target="_blank"><img src={instagram} alt="" className='icon'/></a>
            {/* Agrega aquí tus iconos o enlaces a las redes sociales */}
          </div>
        </div>
      </div>
    </footer>
   </>
  );
}

export default Footer;