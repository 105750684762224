import React, {useState, useEffect} from 'react';
import NavigationBar from '../../../../Components/Nav/NavigationBar';
import { useCarrito } from '../../../Carrito/CarritoContext';
import { Container, Row, Col, Toast } from 'react-bootstrap';
import usos from '../../../../assets/Productos/uriel_y_polo/Fotos/usos.png';
import martinaInicio from '../../../../assets/Productos/martina/Fotos/Martina_foto inicio.webp';
import vista from '../../../../assets/Productos/martina/Señalizacion_materialidad/Martina_Señalizacion_materialidad.webp';
import martinaIMG from '../../../../assets/carrito/Martina.webp';
import dimensiones from '../../../../assets/Productos/martina/Dimensiones/Martina_dimensiones.webp';
import foto1 from '../../../../assets/Productos/martina/Fotos/Martina_izquierda.webp';
import foto2 from '../../../../assets/Productos/martina/Fotos/Martina_derecha.webp';
import config1 from '../../../../assets/Productos/martina/Configuraciones/Martina_configuracion_1.webp';
import config2 from '../../../../assets/Productos/martina/Configuraciones/Martina_configuracion_2.webp';
import final from '../../../../assets/Productos/martina/Fotos/Martina_inferior.webp'; 
import './martina.css';
import GaleryMartina from '../../../../Components/CarrouselProductos/DanteCarrousel/GaleryDante';
import Footer from '../../../../Components/Footer/Footer';
import stripe_logo from '../../../../assets/img/stripe_logo.webp';
import logoToast from '../../../../assets/logo/LogoDiagrama.png';

const Martina = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const StripeMartina = () => {
    window.location.href = 'https://buy.stripe.com/7sIaEN8JF3uj0RabIT';
  };


  const { actualizarCarrito } = useCarrito();
  const [showToast, setShowToast] = useState(false);

  const MartinaProducto = () => {
    actualizarCarrito((carritoActual) => {
    const nuevoProducto = {
      imagen: martinaIMG,
      id: "price_1O4mlwDyGma9TCw9ZK5uScp9",
      nombre: 'Herminio',
      preciounit: 41970,
      precio: 41970,
      cantidad: 1,
    };

    const productoExistente = carritoActual.find((producto) => producto.id === nuevoProducto.id);

    if (productoExistente) {
      productoExistente.cantidad += 1;
    } else {
      carritoActual.push(nuevoProducto);
    }

    setShowToast(true);
    return [...carritoActual];
  });
};

  return (
    <>
    <NavigationBar/>
    <div className='container-fluid'>
        <section>
          <div className='row pt-3'>

            <div className='col-sm-6 col-md-6 col-lg-6'>
              <img src={martinaInicio} alt="" className='imagen' />
            </div>

            <div className='col-sm-6 col-md-6 col-lg-6 d-flex flex-column justify-content-center align-items-center'>
              <div className='text-start m-3'>
                <h2>MARTINA_</h2>
                <p>Mesa auxiliar para distintas ocasiones, una de sus características principales se identifica en las cubiertas de dos niveles. Podría ser el centro de una conversación con gran facilidad para complementar salas y espacios interiores, para lograr una atmósfera cálida desde su materialidad desde el lugar que habita.</p>
                <h2 className='pt-3'>$41,970.00</h2>

                <div className='col-sm-6 col-md-6 col-lg-8 justify-content-start'>
                  <img src={usos} alt="" className='imagen' />
                </div>

                <div className='text-start'>
                  <button className='elementor-button-clara' onClick={MartinaProducto}>Agregar al Carrito</button>
                  <br />
                <p className='pt-3'><button className='elementor-button-clara' onClick={StripeMartina}>Comprar</button></p>
                </div>
              </div>
              
              <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide className='color-toast'>
                <div className=''>
                  <img src={logoToast} className="rounded me-2 imgToast" alt="" />
                  <b>Producto Añadido al Carrito</b>
                </div>
              </Toast>

            </div>
          </div>
        </section>

        <GaleryMartina/>

        <div className='contenedorIMG'>
          <img src={vista} alt="" className='homeImg' />
        </div>

        <div className='contenedorIMG'>
          <img src={dimensiones} alt="" className='homeImg' />
        </div>

        <Container fluid className="pt-5">
          <div className="row">
            <div className='col-sm-12 col-md-6 col-lg-6 m-0'><img src={foto1} alt="" className='imagenjunta1' /></div>
            <div className='col-sm-12 col-md-6 col-lg-6 m-0 pt-2'> <img src={foto2} alt="" className='imagenjunta2' /></div>
          </div>
          <h2 className='text-center pt-3'>Posibles configuraciones y usos</h2>
        </Container>

        <div className='contenedorIMG'>
          <img src={config1} alt="" className='homeImg' />
        </div>

        <div className='contenedorIMG'>
          <img src={config2} alt="" className='homeImg' />
        </div>

        <div className='contenedorIMG'>
          <img src={final} alt="" className='homeImg' />
        </div>
    </div>
    <Footer/>
    </>
  );
}

export default Martina;