import React from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './Pages/Home/Home';
import Colaboracion from './Pages/Colaboracion/Colaboracion';
import Piezas from './Pages/Piezas/Piezas';
import Carrito from './Pages/Carrito/Carrito';
import Uriel from './Pages/Piezas/Productos/Uriel_&_Polo/Uriel';
import Herminio from './Pages/Piezas/Productos/Herminio/Herminio';
import Igor from './Pages/Piezas/Productos/Igor/Igor';
import Clara from './Pages/Piezas/Productos/Clara/Clara';
import Lina from './Pages/Piezas/Productos/Lina/Lina';
import Dante from './Pages/Piezas/Productos/Dante/Dante';
import Martina from './Pages/Piezas/Productos/Martina/Martina';
import Contacto from './Pages/Contacto/Contacto';
import { CarritoProvider } from './Pages/Carrito/CarritoContext';

import './App.css';

function App() {
  return (
   <CarritoProvider>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/colaboracion' element={<Colaboracion/>}/>
        <Route path='/piezas' element={<Piezas/>}/>
        <Route path='/carrito' element={<Carrito/>}/>
        <Route path='/uriel&polo' element={<Uriel/>}/>
        <Route path='/herminio' element={<Herminio/>}></Route>
        <Route path='/igor' element={<Igor/>}></Route>
        <Route path='/clara' element={<Clara/>}></Route>
        <Route path='/lina' element={<Lina/>}></Route>
        <Route path='/dante' element={<Dante/>}></Route>
        <Route path='/martina' element={<Martina/>}></Route>
        <Route path='/contacto' element={<Contacto/>}></Route>
      </Routes>
    </BrowserRouter>
    </CarritoProvider>
  );
}

export default App;
