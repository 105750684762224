import React from 'react';
import NavigationBar from '../../Components/Nav/NavigationBar';
import Footer from '../../Components/Footer/Footer';
import BannerContacto from '../../assets/img/Contacto_Diagrama.webp';
import FormContacto from '../../Components/FormContacto/FormContacto';
import './contacto.css';

const Contacto = () => {
  return (
   <>
   <NavigationBar/>
      <div className='contenedorIMG'>
        <img src={BannerContacto} alt="" className='homeImg' />
      </div>
<FormContacto/>


   <Footer/>
   </>
  );
}

export default Contacto;