import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import './formcontacto.css';
import axios from 'axios';
import modalIMG from '../../assets/img/modal_diagrama.png';

const FormContacto = () => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    compania: '',
    mensaje: '',
  });

  const [modalVisible, setModalVisible] = useState(false);

  const mostrarModal = () => {
    setModalVisible(true);
  }

  const cerrarModal = () => {
    setModalVisible(false);
  }

  const validarFormulario = () => {
    const { nombre, email, compania, mensaje } = formData;

    if (!nombre || !email || !compania || !mensaje) {
      alert("El formulario está incompleto. Por favor, llene todos los campos.");
      return false;
    }

    return true;
  }

  const enviarDatos = async () => {
    if (validarFormulario()) {
      try {
        const response = await axios.post('https://pruebas-production-b73f.up.railway.app/api/diagrama/send-email/', formData);

        console.log('Respuesta de la API:', response.data);

        // Limpiar el formulario después de enviar los datos
        setFormData({
          nombre: '',
          email: '',
          compania: '',
          mensaje: '',
        });

        // Mostrar modal de éxito
        handleShow();
      } catch (error) {
        console.error('Error en la solicitud:', error);
      }
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  return (
    <>
    <Modal show={show} onHide={handleClose} dialogClassName='modalSize'>
      <div className='modalFondo'>
        <div className='text-end'>
        <button onClick={handleClose} className=' btn text-end'><FontAwesomeIcon icon={faClose} color="white" size="2x" style={{ fontSize: '24px' }} /></button>
        </div>
        <div className='text-center'>
        <h1>¡Gracias!</h1>
        <p>
          A la brevedad nos pondremos en contacto para confirmar tu cita.
        </p>
        <img
          className="imgStyle"
          src={modalIMG}
          alt=""
        />
        </div>
      </div>
    </Modal>

    <div className='container espacio'>
       <div className="row">
       <div className="col-sm-12 col-md-7 col-lg-7 p-5">

            <div>
              <a class="title d-md-none d-lg-none">¿QUIERES CONOCER LA COLECCIÓN?</a><br />
              <p class="subtitle d-md-none d-lg-none"><b>AGENDA TU CITA</b></p>
            </div>

        <form id="miFormulario" className="formsend">

                <label htmlFor="nombre" className="label">NOMBRE*</label>
                <p>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  className="input"
                  value={formData.nombre}
                  onChange={handleInputChange}
                  required
                />
                </p>

                <label htmlFor="email" className="label">EMAIL*</label>
                <p><input
                  type="email"
                  id="email"
                  name="email"
                  className="input"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                /></p>
        
                <label htmlFor="compania" className="label">COMPAÑIA/ORGANIZACIÓN</label>
                <p><input
                  type="text"
                  id="compania"
                  name="compania"
                  className="input"
                  value={formData.compania}
                  onChange={handleInputChange}
                  required
                /></p>
             
                <label htmlFor="mensaje" className="label">MENSAJE</label>
                <p><textarea
                  id="mensaje"
                  name="mensaje"
                  className="input-area"
                  value={formData.mensaje}
                  onChange={handleInputChange}
                  required
                ></textarea></p>

              <button type="button" onClick={enviarDatos} className="btn enviar">ENVIAR</button>
            </form>
        </div>


        <div className="col-sm-12 col-md-5 col-lg-5 p-4">
        <div className='text-start'>
              <a class="title d-none d-md-block">¿QUIERES CONOCER LA COLECCIÓN?</a><br />

              <p class="subtitle d-none d-md-block"><b>AGENDA TU CITA</b></p>
              <div className='pt-5'>
                <a class="infotitle " ><b>GUADALAJARA</b></a>

                <br /><a class="infotext" >Avenida Paseo Punto Sur #312</a>

                <br /><a class="infotext" >Nivel 1, Interior V2,</a>

                <br /><a class="infotext" >Corporativo Punto Sur</a>

                <br /><a class="infotext" >CP. 45645.</a>

                <br /><a class="infotext" >Tlajomulco de Zúñiga, Jalisco.</a>

                <br /><a class="infotext" >+52 (33) 3833 7788</a>

            </div>
        </div>
        </div>
       </div>
    </div>

    </>
  );
}

export default FormContacto;
