import React from 'react';
import {Container} from 'react-bootstrap';
import Artesanos1 from '../../assets/galeriaColaboracion/Artesanos_1.webp';
import Artesanos2 from '../../assets/galeriaColaboracion/Artesanos_2.webp';
import Artesanos3 from '../../assets/galeriaColaboracion/Artesanos_3.webp';
import Artesanos4 from '../../assets/galeriaColaboracion/Artesanos_4.webp';
import Artesanos5 from '../../assets/galeriaColaboracion/Artesanos_5.webp';
import Artesanos6 from '../../assets/galeriaColaboracion/Artesanos_6.webp';
import Artesanos7 from '../../assets/galeriaColaboracion/Artesanos_7.webp';
import Artesanos8 from '../../assets/galeriaColaboracion/Artesanos_8.webp';
import './galerycolaboracion.css';

//importaciones necesarias para la galeria de swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';



const CarrouselColaboracion = () => {
  return (
    <Container className=''>
        <Swiper
        slidesPerView={1}
        spaceBetween={15}
        loop={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide><img src={Artesanos1} alt="" /></SwiperSlide>
        <SwiperSlide><img src={Artesanos2} alt="" /></SwiperSlide>
        <SwiperSlide><img src={Artesanos3} alt="" /></SwiperSlide>
        <SwiperSlide><img src={Artesanos4} alt="" /></SwiperSlide>
        <SwiperSlide><img src={Artesanos5} alt="" /></SwiperSlide>
        <SwiperSlide><img src={Artesanos6} alt="" /></SwiperSlide>
        <SwiperSlide><img src={Artesanos7} alt="" /></SwiperSlide>
        <SwiperSlide><img src={Artesanos8} alt="" /></SwiperSlide>
      </Swiper>
    </Container>
  );
}

export default CarrouselColaboracion;