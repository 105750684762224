import React, {useEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NavigationBar from '../../Components/Nav/NavigationBar';
import BannerPiezas from '../../assets/img/banner_piezas.webp';
import uriel from '../../assets/Productos/uriel_y_polo/uriel&polo.webp';
import herminio from '../../assets/Productos/herminio/herminio.webp';
import igor from '../../assets/Productos/igor/Igor.webp';
import clara from '../../assets/Productos/clara/Clara.webp';
import lina from '../../assets/Productos/lina/Lina.webp'; 
import dante from '../../assets/Productos/dante/dante.webp';
import martina from '../../assets/Productos/martina/martina.jpeg';
import Flotante from '../Contacto/botonFlotante/Flotante';
import Footer from '../../Components/Footer/Footer';

import './piezas.css';

const Piezas = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
        <NavigationBar/>
        <div className='contenedorIMG'>
                <img src={BannerPiezas} alt="" className='imagen' />
        </div>

        <Container fluid className="">
                <Row className="m-0 pt-4 p-5">
                   <Link to="/uriel&polo" className="p-0 col-sm-12 col-md-8 col-lg-8 mb-3"><img src={uriel} alt="" className='imagen' /></Link>
                    <Link to="/herminio" className="p-0 col-sm-12 col-md-4 col-lg-4 mb-3"><img src={herminio} alt="" className='imagen' /></Link>
                </Row>

                <Row className="m-0 pt-4 p-5">
                    <Link to="/igor" className="p-0 col-md-4 col-lg-4 mb-5 mr-5"><img src={igor} alt="" className='imagen' /></Link>
                    <Link to="/clara" className="p-0 col-md-4 col-lg-4 mb-5 mr-5"><img src={clara} alt="" className='imagen' /></Link>
                    <Link to="/lina" className="p-0 col-md-4 col-lg-4 mb-5"><img src={lina} alt="" className='imagen' /></Link>
                </Row>

                <Row className="m-0 pt-4 p-5">
                    <Link to="/dante" className="p-0 col-md-4 col-lg-4 mb-5 mr-5"><img src={dante} alt="" className='imagen' /></Link>
                    <Link to="/martina" className="p-0 col-md-8 col-lg-8 mb-5 mr-5"><img src={martina} alt="" className='imagen' /></Link>
                </Row>
            </Container>
    <Flotante/>
    <Footer/>
    </>
  );
}

export default Piezas;