import React from 'react';
import {Container} from 'react-bootstrap';
import Clara_y_Lina from '../../assets/galeriaProductos/Carrusel_productos/Clara_y_Lina.webp';
import Dante from '../../assets/galeriaProductos/Carrusel_productos/Dante.webp'; 
import Herminio from '../../assets/galeriaProductos/Carrusel_productos/Herminio.webp';
import igor from '../../assets/galeriaProductos/Carrusel_productos/Igor.webp';
import Martina from '../../assets/galeriaProductos/Carrusel_productos/Martina.webp';
import Uriel_y_Polo from '../../assets/galeriaProductos/Carrusel_productos/Uriel_y_Polo.webp';


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Keyboard, Pagination, Navigation } from 'swiper/modules';

import './galeryhome.css';


const GaleryHome = () => {
  return (
    <Container className='pt-3'>
    <Swiper 
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        keyboard={{
          enabled: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Keyboard, Pagination, Navigation]}
        className="mySwiper text-dark"
      >
        <SwiperSlide><img src={Clara_y_Lina} alt="" /></SwiperSlide>
        <SwiperSlide><img src={Dante} alt="" /></SwiperSlide>
        <SwiperSlide><img src={Herminio} alt="" /></SwiperSlide>
        <SwiperSlide><img src={igor} alt="" /></SwiperSlide>
        <SwiperSlide><img src={Martina} alt="" /></SwiperSlide>
        <SwiperSlide><img src={Uriel_y_Polo} alt="" /></SwiperSlide>
      </Swiper>
    </Container>
  );
}

export default GaleryHome;
