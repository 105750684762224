import React, { useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { CarritoContext } from '../CarritoContext';

const Contador = () => {
  const { carrito, actualizarCarrito } = useContext(CarritoContext);
  const [conteoCarrito, setConteoCarrito] = React.useState(0);

  useEffect(() => {
    const totalItems = carrito.reduce((total, producto) => total + producto.cantidad, 0);
    setConteoCarrito(totalItems);
  }, [carrito]);

  return (
    <div className='pt-0'>
      <Link to="/carrito" type="button" className="btn position-relative">
        <FontAwesomeIcon icon={faCartShopping} className='text-white'/>
        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill text-dark bg-white">
          {conteoCarrito}
        </span>
      </Link>
    </div>
  );
};

export default Contador;
