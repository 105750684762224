import React, {useEffect} from 'react';
import NavigationBar from '../../Components/Nav/NavigationBar';
import {Container, Row, Col} from 'react-bootstrap';
import BannerSuperior from '../../assets/img/Banner_superior_home.webp';
import Presentacion1 from '../../assets/img/Presentacion_1.webp';
import Presentacion2 from '../../assets/img/Presentacion_2.webp';
import Vectores from '../../assets/img/Vectores-productos.webp';
import GaleryHome from '../../Components/CarrouselHome/GaleryHome';
import { Link } from 'react-router-dom';
import Flotante from '../Contacto/botonFlotante/Flotante';
import Footer from '../../Components/Footer/Footer';
import './home.css';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
    <NavigationBar/>
      
      <div>
      <div className='contenedorIMG'>
        <img src={BannerSuperior} alt="" className='homeImg' />
      </div>

      <div className="p-0">
        <Row className="m-0 ">
          <Col sm={12} md={6} className="p-0"><img src={Presentacion1} alt="" className='homeImg' /></Col>
          <Col sm={12} md={6} className="p-0"><img src={Presentacion2} alt="" className='homeImg' /></Col>
        </Row>
  
      </div>

    <div className='pt-5 text-center video'>

    <iframe width="80%" height="700" src="https://www.youtube.com/embed/OCIQR6IPYm0?si=HNu33lk9rm52V47k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

        <div className='pt-3'>
          <GaleryHome/>
        </div>

        <div className='contenedorIMG'>
        <img src={Vectores} alt="" className='homeImg' />
      </div>
      <Footer/>
    </div>   
      </div>
     
<Flotante/>

    </>
  );
}

export default Home;