import React, {useState, useEffect} from 'react';
import NavigationBar from '../../../../Components/Nav/NavigationBar';
import { useCarrito } from '../../../Carrito/CarritoContext';
import { Container, Row, Col, Toast} from 'react-bootstrap';
import claraInicio from '../../../../assets/Productos/clara/Fotos/Clara_foto_inicio.webp';
import claraIMG from '../../../../assets/carrito/Clara.webp';
import usos from '../../../../assets/Productos/uriel_y_polo/Fotos/usos.png';
import vista from '../../../../assets/Productos/clara/Señalizacion_materialidad/Clara_Señalizacion_materialidad_1.webp';
import dimensiones from '../../../../assets/Productos/clara/Dimensiones/Clara_dimensiones.webp';
import foto1 from '../../../../assets/Productos/clara/Fotos/Clara_izquierda.webp';
import foto2 from '../../../../assets/Productos/clara/Fotos/Clara_derecha.webp';
import config1 from '../../../../assets/Productos/clara/Configuraciones/Clara_y_lina_configuracion_1.webp';
import config2 from '../../../../assets/Productos/clara/Configuraciones/Clara_y_lina_configuracion_2.webp';
import final from '../../../../assets/Productos/clara/Fotos/Clara_inferior.webp';
import GaleryClara from '../../../../Components/CarrouselProductos/ClaraCarrousel/GaleryClara';
import Footer from '../../../../Components/Footer/Footer';
import stripe_logo from '../../../../assets/img/stripe_logo.webp';
import logoToast from '../../../../assets/logo/LogoDiagrama.png';
import './clara.css';

const Clara = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const StripeClara = () => {
    window.location.href = 'https://buy.stripe.com/5kAeV3f833ujgQ8009';
  };

  const { actualizarCarrito } = useCarrito();
  const [showToast, setShowToast] = useState(false);

  const ClaraProducto = () => {
    actualizarCarrito((carritoActual) => {
    const nuevoProducto = {
      imagen: claraIMG,
      id: "price_1O2fMYDyGma9TCw9ZbtXJSUp",
      nombre: 'Herminio',
      preciounit: 41560,
      precio: 41560,
      cantidad: 1,
    };

    const productoExistente = carritoActual.find((producto) => producto.id === nuevoProducto.id);

    if (productoExistente) {
      productoExistente.cantidad += 1;
    } else {
      carritoActual.push(nuevoProducto);
    }
    setShowToast(true);
    return [...carritoActual];
  });
};


  return (
   <>
   <NavigationBar/>
   <div className='container-fluid'>
        <section>
          <div className='row pt-3'>

            <div className='col-sm-6 col-md-6 col-lg-6'>
              <img src={claraInicio} alt="" className='imagen' />
            </div>

            <div className='col-sm-6 col-md-6 col-lg-6 d-flex flex-column justify-content-center align-items-center'>
              <div className='text-start m-3'>
                <h2>CLARA_</h2>
                <p>Set de lámparas enfocadas en iluminar desde diferentes alturas el espacio que habitan. Proyectan una iluminación de superficies horizontales, en cada una de sus versiones facilita las actividades del día a día, sea de piso o de mesa. Uno de sus atractivos principales es aprovechar el uso de la cubierta principal como mesa de apoyo para momentos de trabajo o esparcimiento.</p>
                <h2 className='pt-3'>$41,560.00</h2>

                <div className='col-sm-6 col-md-6 col-lg-8 justify-content-start'>
                  <img src={usos} alt="" className='imagen' />
                </div>

                <div className='text-start'>
               <button className='elementor-button-clara mb-3' onClick={ClaraProducto}>Agregar al Carrito</button>
               <br />
                <p className='pt-3'><button className='elementor-button-clara' onClick={StripeClara}>Comprar</button></p>
                </div>
              </div>

              <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide className='color-toast'>
        <div className=''>
          <img src={logoToast} className="rounded me-2 imgToast" alt="" />
          <b>Producto Añadido al Carrito</b>
          </div>
        </Toast>

            </div>
          </div>
        </section>

        <GaleryClara/>

        <div className='contenedorIMG'>
          <img src={vista} alt="" className='homeImg' />
        </div>

        <div className='contenedorIMG'>
          <img src={dimensiones} alt="" className='homeImg' />
        </div>

        <Container fluid className="pt-5">
          <div className="row">
            <div className='col-sm-12 col-md-6 col-lg-6 m-0'><img src={foto1} alt="" className='imagenjunta1' /></div>
            <div className='col-sm-12 col-md-6 col-lg-6 m-0 pt-2'> <img src={foto2} alt="" className='imagenjunta2' /></div>
          </div>
          <h2 className='text-center pt-3'>Posibles configuraciones y usos</h2>
        </Container>

        <div className='contenedorIMG'>
          <img src={config1} alt="" className='homeImg' />
        </div>

        <div className='contenedorIMG'>
          <img src={config2} alt="" className='homeImg' />
        </div>

        <div className='contenedorIMG'>
          <img src={final} alt="" className='homeImg' />
        </div>
   </div>
   <Footer/>
   </>
  );
}

export default Clara;